export const LAYOUT_TYPE_EMPTY = 'empty';
export const LAYOUT_TYPE_1_COLUMN = '1column';
export const LAYOUT_TYPE_2_COLUMNS_LEFT = '2columns-left';
export const LAYOUT_TYPE_2_COLUMNS_RIGHT = '2columns-right';
export const LAYOUT_TYPE_3_COLUMNS = '3columns';
export const LAYOUT_TYPE_2_COLUMNS_LEFT_BREADCRUMBS = '2columns-left-breadcrumbs';
export const LAYOUT_TYPE_1_COLUMN_BREADCRUMBS = '1column-breadcrumbs';

export const LAYOUT_TYPES = {
    LAYOUT_TYPE_EMPTY,
    LAYOUT_TYPE_1_COLUMN,
    LAYOUT_TYPE_2_COLUMNS_LEFT,
    LAYOUT_TYPE_2_COLUMNS_RIGHT,
    LAYOUT_TYPE_3_COLUMNS,
    LAYOUT_TYPE_2_COLUMNS_LEFT_BREADCRUMBS,
    LAYOUT_TYPE_1_COLUMN_BREADCRUMBS,
};
